import Dispatcher from "../common/dispatcher";
import PayWithPlaid from "./components/pay_with_plaid";

export default class App {
  constructor() {
    Dispatcher.run(this);
  }

  invoicesShow() {
    window.payWithPlaid = new PayWithPlaid($("header"));
  }
}
