export default class PayWithPlaid {
  constructor(node) {
    this.node = node;
    this.button = this.node.find(".btn-plaid");
    this.payUrl = this.button.prop("href");
    this.plaidHandler = Plaid.create({
      clientName: "Astor Place Labs",
      env: process.env.PLAID_ENVIRONMENT,
      key: process.env.PLAID_PUBLIC_KEY,
      apiVersion: "v2",
      product: "auth",
      selectAccount: true,
      onSuccess: (...args) => this.prepareForm(...args)
    });
    this.button.click(() => {
      this.plaidHandler.open();
      return false;
    });
  }

  prepareForm(publicToken, metadata) {
    this.node
      .find(".link-step")
      .addClass("d-none")
      .removeClass("d-flex");
    this.node.find(".pay-step").removeClass("d-none");
    this.node.find("#public_token").val(publicToken);
    this.node.find("#account_id").val(metadata.account_id);
  }
}
